<template>
    <div>
        <FullMenu />
    </div>
</template>

<script>
import FullMenu from "../components/FullMenu";
export default {
    name: "Menu",
    components: { FullMenu }
};
</script>

<style scoped></style>
